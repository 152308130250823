import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":"","color":"transparent"}},[_c(VCardTitle,[_vm._v(" Tokens de Acesso "),_c(VChipGroup,{staticClass:"ml-4",attrs:{"active-class":"primary","mandatory":""},model:{value:(_vm.revoked),callback:function ($$v) {_vm.revoked=$$v},expression:"revoked"}},[_c(VChip,{attrs:{"value":false}},[_vm._v("Ativos")]),_c(VChip,{attrs:{"value":true}},[_vm._v("Revogados")])],1),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","grow":"","to":"tokens-acesso/create"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1),_c('pages-configuracoes-sistema-tokens-acesso-list',{attrs:{"type":'api',"revoked":_vm.revoked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }