<template>
  <div>
    <v-card>
      <v-data-table
          disable-filtering
          disable-sort
          hide-default-footer
          :headers="headers"
          :items="items"
          :items-per-page="itemsPerPage"
          :loading="listProcessing"
          :page.sync="page"
      >
        <template #item.revoked="{ item }">
          <v-chip
              :color="item.revoked ? 'red lighten-2' : 'green lighten-2'"
              dark
              label
              small
          >
            <strong>{{ item.revoked ? 'Revogado' : 'Ativo' }}</strong>
          </v-chip>
        </template>
        <template #item.issued_at="{ item }">
          <span>{{ item.issued_at | datetime }}</span>
        </template>
        <template #item.expires_at="{ item }">
          <span>{{ item.expires_at | datetime('Não Expira') }}</span>
        </template>
        <template #item.last_used_at="{ item }">
          <span>{{ item.last_used_at | datetime('-') }}</span>
        </template>
        <template #item.actions="{ item }">
          <v-icon @click="revokeItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-pagination
        v-if="items.length > 0"
        v-model="page"
        class="py-2"
        :length="pageCount"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
    ></v-pagination>
    <v-dialog v-model="revokeDialog" max-width="350px" persistent>
      <v-card :disabled="revokeProcessing" :loading="revokeProcessing">
        <v-card-text class="title pt-4">Você tem certeza que quer revogar este token?</v-card-text>
        <v-card-actions>
          <v-spacer />
            <v-btn color="grey" :disabled="revokeProcessing" text @click="revokeClose">Cancelar</v-btn>
            <v-btn color="primary" :disabled="revokeProcessing" depressed @click="revokeItemConfirm">Sim</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <custom-snackbar ref="snackbar" />
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  name: 'PagesConfiguracoesSistemaTokensAcessoList',

  props: {
    revoked: {
      type: Boolean,
      default: null,
    },
    type: {
      type: String,
      default: null,
    }
  },

  data: () => ({
    headers: [{
      text: 'Descrição',
      value: 'name',
      class: 'primary rounded-tl white--text',
    }, {
      text: 'Proprietário',
      value: 'user.name',
      class: 'primary white--text',
    }, {
      text: 'Emissor',
      value: 'issuer.name',
      class: 'primary white--text',
    }, {
      text: 'Emissão',
      value: 'issued_at',
      align: 'center',
      class: 'primary white--text',
    }, {
      text: 'Vencimento',
      value: 'expires_at',
      align: 'center',
      class: 'primary white--text',
    }, {
      text: 'Última Utilização',
      value: 'last_used_at',
      align: 'center',
      class: 'primary white--text',
    }, {
      text: 'Status',
      value: 'revoked',
      align: 'center',
      class: 'primary white--text',
    }, {
      text: '',
      value: 'actions',
      width: 0,
      class: 'rounded-tr primary white--text',
    }],
    items: [],
    itemsPerPage: 20,
    listProcessing: true,
    page: 1,
    pageCount: 0,
    revokedIndex: -1,
    revokedItem: null,
    revokeDialog: false,
    revokeProcessing: false
  }),

  watch: {
    page () {
      this.load()
    },

    itemsPerPage () {
      // evita que ao mudar a páginas sejam geradas  duas chamadas da api
      if (this.page !== 1) {
        this.page = 1
      } else {
        this.load()
      }
    },

    revoked () {
      this.load()
    }
  },

  async mounted() {
    this.load()
  },

  methods: {
    async load () {
      this.listProcessing = true

      this.items = []

      const params = {
        per_page: this.itemsPerPage,
        page: this.page,
        type: this.type,
        revoked: this.revoked,
      }

      const result = (await ApiService.get('tokens', params)).data

      this.items = result.data
      this.pageCount = result.last_page

      this.listProcessing = false
    },

    revokeItem (item) {
      this.revokedIndex = this.items.indexOf(item)
      this.revokedItem = Object.assign({}, item)
      this.revokeDialog = true
    },

    revokeClose () {
      this.revokedIndex = -1
      this.revokedItem = null
      this.revokeDialog = false
    },

    async revokeItemConfirm () {
      this.revokeProcessing = true

      try {
        await ApiService.put(`tokens/${this.revokedItem.id}/revoke`)

        this.revokedItem.revoked = true;

        this.revokeClose()

        await this.load()

      } catch (e) {
        console.log(e)
        if (e.response) {
          if (e.response.data.errors) {
            this.form.errors = e.response.data.errors
          }

          this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              e.response.data.message,
              'danger',
          )
        } else {
          this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              'Não foi possível atender a sua requisição.',
              'danger',
          )
        }
      }

      this.revokeProcessing = false
    }
  }
}
</script>