<template>
  <v-card flat color="transparent">
    <v-card-title>
      Tokens de Acesso
      <v-chip-group
          v-model="revoked"
          active-class="primary"
          class="ml-4"
          mandatory
      >
        <v-chip :value="false">Ativos</v-chip>
        <v-chip :value="true">Revogados</v-chip>
      </v-chip-group>
      <v-spacer />
      <v-btn
          color="primary"
          grow
          to="tokens-acesso/create"
      >
        <v-icon left>mdi-plus</v-icon>
        Adicionar
      </v-btn>
    </v-card-title>

    <pages-configuracoes-sistema-tokens-acesso-list :type="'api'" :revoked="revoked"/>

  </v-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import PagesConfiguracoesSistemaTokensAcessoList from "@/view/pages/configuracoes/sistema/TokenAcesso/Components/List";

export default {
  name: 'PagesConfiguracoesSistemaTokensAcesso',
  components: {PagesConfiguracoesSistemaTokensAcessoList},
  data: () => ({
    tabs: null,
    revoked: false
  }),

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações' },
      { title: 'Sistema' },
      { title: 'Tokens de Acesso' },
    ])
  },
}
</script>