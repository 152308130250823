import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDataTable,{attrs:{"disable-filtering":"","disable-sort":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"loading":_vm.listProcessing,"page":_vm.page},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.revoked",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":item.revoked ? 'red lighten-2' : 'green lighten-2',"dark":"","label":"","small":""}},[_c('strong',[_vm._v(_vm._s(item.revoked ? 'Revogado' : 'Ativo'))])])]}},{key:"item.issued_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.issued_at)))])]}},{key:"item.expires_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.expires_at,'Não Expira')))])]}},{key:"item.last_used_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.last_used_at,'-')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{on:{"click":function($event){return _vm.revokeItem(item)}}},[_vm._v("mdi-delete")])]}}])})],1),(_vm.items.length > 0)?_c(VPagination,{staticClass:"py-2",attrs:{"length":_vm.pageCount,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c(VDialog,{attrs:{"max-width":"350px","persistent":""},model:{value:(_vm.revokeDialog),callback:function ($$v) {_vm.revokeDialog=$$v},expression:"revokeDialog"}},[_c(VCard,{attrs:{"disabled":_vm.revokeProcessing,"loading":_vm.revokeProcessing}},[_c(VCardText,{staticClass:"title pt-4"},[_vm._v("Você tem certeza que quer revogar este token?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","disabled":_vm.revokeProcessing,"text":""},on:{"click":_vm.revokeClose}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.revokeProcessing,"depressed":""},on:{"click":_vm.revokeItemConfirm}},[_vm._v("Sim")]),_c(VSpacer)],1)],1)],1),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }